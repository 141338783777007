<template>
  <v-container fluid>
    <!--HEADER-->
    <v-app-bar :color=colorMenu flat app dense height="50px" dark>
          <v-btn icon :to="{ name: complejo }">
                <v-icon>mdi-home-outline</v-icon>
          </v-btn>
          <v-toolbar-title>{{ tituloHeader }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon  @click="generar_imprimir">
                  <v-icon>mdi-printer</v-icon>
          </v-btn>
          <template v-slot:extension>        
                    <v-tabs dense icons-and-text dark grow
                      centered
                      v-model="tabs"
                      v-show="mostrarTab"
                      color="white">
                            <v-tab
                              dense
                              class="caption"
                              v-for="(itema, a) in itemTabs"
                              :key="`${a}-${itema.id}`"
                              @click="filtroPorTipo(itema.idpadre)">
                                    <v-row dense>
                                             {{ itema.descripcion }}
                                   <br>
                                               {{ itema.descripcion2 }}
                                    </v-row>                                
                                    <v-icon dense>{{ itema.icono }}</v-icon>
                            </v-tab>
                    </v-tabs>
          </template>
    </v-app-bar>
    <v-tabs-items v-model="tabs" @change="cambio(tabs)">      
          <v-tab-item v-for="(itemtab, i) in itemTabs" :key="`${i}-${itemtab.id}`">
                <v-btn color="blue" class="mb-4" dense small block outlined  @click="nuevoItemCategoria(itemtab)">NUEVA CATEGORIA EN {{ itemtab.descripcion}} </v-btn>
                <v-divider></v-divider>
                <br>
                <v-list
                  dense
                  subheader
                  v-for="(item, j) in categoriasObtenidasFiltrada"
                  :key="`${j}-${item.id}`"
                  :id="item.categoria" >
                        <v-subheader class="text-uppercase body-2 font-weight-black font-italic item">
                                  <v-icon color="green  " class="ma-0 pa-0">mdi-menu-right</v-icon>
                                  {{ item.categoria }}
                                  <v-btn class="mx-2"  x-small outlined color="blue" @click="editarCategoria(item)" >                
                                        <v-icon small>mdi-pencil</v-icon>
                                  </v-btn>
                                  <v-btn class="mx-0"  outlined x-small color="red" @click="borrarCategoria(item)">                
                                         <v-icon small>mdi-delete</v-icon>
                                  </v-btn>                  
                        </v-subheader>                        
                        
                          <v-data-table no-data-text="Sin datos"
                                dense
                                :headers="campos"
                              :items="filtroPorCategorias(item.id)"
                              disable-pagination
                                :hide-default-footer="true">
                                      <template v-slot:[`item.acciones`]="{ item }" >   
                                              <v-btn icon small color="blue" @click="editar(item)" >                
                                                      <v-icon small>mdi-pencil</v-icon>
                                              </v-btn>
                                              <v-btn icon small color="red" @click="borrar(item)">                
                                                      <v-icon small>mdi-delete</v-icon>
                                              </v-btn>                     
                                      </template>                 
                          </v-data-table>
                          <v-card v-if="item.pie" color="green" dark class="mb-4 mx-4 pa-2 caption text-center" elevation-1>
                                 <span v-html="item.pie"></span>
                          </v-card>
                          <v-btn color="blue" class="my-2" dense small block outlined  @click="nuevoItem(item)">AGREGAR EN {{ item.categoria}} </v-btn>
                          <v-divider></v-divider>
                </v-list>
          </v-tab-item>
    </v-tabs-items>
     <v-dialog v-model=dialog v-if=dialog persistent width="600px">                 
                                <v-card>
                                        <v-card-title>
                                                <span v-if="editItem.id">Modificar: {{editItem.item}}</span>
                                                <span v-else>Agregar</span>
                                        </v-card-title>
                                        <v-card-text dense>
                                                  <v-row>                                        
                                                        <v-col cols="12" sm="12" class="ma-0 pa-1">
                                                              <v-text-field v-model="editItem.item" label="Descripcion" dense outlined  autofocus placeholder=" "></v-text-field>
                                                        </v-col> 
                                                        <v-col cols="12" sm="12"  class="ma-0 pa-1">
                                                              <v-text-field v-model="editItem.descripcion" label="Detalle" dense outlined></v-text-field>
                                                        </v-col>            
                                                        <v-col cols="12" sm="12"  class="ma-0 pa-1">
                                                              <v-text-field v-model="editItem.descripcion2" label="Detalle 2" dense outlined  placeholder=" "></v-text-field>
                                                        </v-col> 
                                                        <v-col cols="12" sm="12" class="ma-0 pa-1">
                                                              <v-text-field v-model="editItem.descripcion3" label="Detalle 3" outlined dense placeholder=" "></v-text-field>
                                                        </v-col> 
                                                        <v-col cols="6" sm="6" class="ma-0 pa-1">
                                                          <v-text-field v-model="editItem.precio" prefix="$" label="Precio" dense outlined placeholder=" "></v-text-field>
                                                        </v-col >
                                                        <v-col cols="6" sm="6" class="ma-0 pa-1">
                                                          <v-text-field v-model="editItem.preciosocio" prefix="$" label="Precio Socio" dense outlined  placeholder=" "></v-text-field>
                                                        </v-col>                                  
                                                    </v-row>                                      
                                        </v-card-text>
                                        <v-card-actions >                                               
                                                <v-spacer></v-spacer>
                                                <v-btn color="green" class="mx-2 pa-4" dark dense @click="close()">Cancelar</v-btn>                                                
                                                <v-btn color="green" class="mx-2 pa-4" dark dense  @click="grabarItem(editItem)">Guardar Cambio</v-btn>
                                        </v-card-actions>
                                </v-card>
    </v-dialog>
    <v-dialog v-model=dialogCategoria v-if=dialogCategoria persistent width="600px">                 
                                <v-card>
                                        <v-card-title>
                                            <span v-if="editItemCategoria.id">Modificar</span>
                                            <span v-else>Agregar</span>
                                        </v-card-title>
                                        <v-card-text dense>
                                                <v-row>                                        
                                                    <v-col cols="12" sm="12" class="ma-0 pa-1">
                                                      <v-text-field v-model="editItemCategoria.categoria" label="Categoria" dense outlined autofocus placeholder=" "></v-text-field>
                                                    </v-col> 
                                                    <v-col cols="12" sm="12"  class="ma-0 pa-1">
                                                      <v-textarea rows="3" v-model="editItemCategoria.pie" label="Pie" placeholder=" " outlined></v-textarea>
                                                    </v-col>                                  
                                                  </v-row>                                      
                                        </v-card-text>
                                        <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="green" dense class="mx-2 pa-4" dark @click="close()">Cancelar</v-btn>
                                                <v-btn color="green" dense class="mx-2 pa-4" dark  @click="grabarItemCategoria(editItemCategoria)">Guardar Cambio</v-btn>
                                        </v-card-actions>
                                </v-card>
      </v-dialog>
      <a id='final_pagina'></a> 
  </v-container>  
</template>
<script>

import axios from 'axios'
export default {
  name:'cartaAdmin',
  props: {
      tituloHeader: String,
      complejo: String,
      idcomplejo:Number,
      idmenu:Number,
      colorMenu:String,
      moneda:String,
  },
  data() {
    return {
      

      dialog: false, // used to toggle the dialog
      dialogCategoria: false,
      editItem: {},
      editItemCategoria:{},
      valorOriginal: {},
    
     
      categoriaElegida:'',
      mostrarTab: "false",
      activeBtn: 1,
      tabs: null,
      showNav: true,
      cartaObtenida: [],
      categoriasObtenidas: [],
      vinosObtenidos: [],
      categoriasObtenidasFiltrada: [],
      cartaObtenidaFiltrada: [],
      buscar: "",
      campos:[
               {
                 text: 'Nombre',
                 sortable:false,
                 value: 'item'
               },
               {
                 text:'Detalle',
                 sortable:false,
                 value:'descripcion'
               },
               {
                 text:'Detalle2',
                 sortable:false,
                 value:'descripcion2'
               },
               {
                 text:'Detalle3',
                 sortable:false,
                 value:'descripcion3'
               },

               {
                 text:'Precio',
                 sortable:false,
                 value:'precio'
               },
               {
                 text:'PrecioSocio',
                 sortable:false,
                 value:'preciosocio'
               },
               {
                 text:'Acciones',
                 sortable:false,
                 value:'acciones'
               }
      ],
      itemTabs: []
    };
  },
  created: async function() {

         axios.get(process.env.VUE_APP_API +'/complejo/menu_tabs',
             {params:{
                   idcomplejo: this.idcomplejo,
                   menu: this.idmenu
                    }
                 })
              .then((response) => {
                          this.itemTabs= response.data
               })   
         axios.get(process.env.VUE_APP_API +'/complejo/carta',
                 {params:{
                   idcomplejo: this.idcomplejo
                     }
          })
      .then((response) => {
                          this.cartaObtenida= response.data
                        this.cartaObtenidaFiltrada=response.data
      })
       await axios.get(process.env.VUE_APP_API +'/complejo/cartacategorias',
              {params:{
                   idcomplejo: this.idcomplejo
                     }
          })
             .then((response) => {
               // alert(JSON.stringify(response.data))
                                 this.categoriasObtenidas= response.data
                              //   alert(JSON.stringify(response.data))
                               this.categoriasObtenidasFiltrada=this.categoriasObtenidas
       })
       this.cambio(0)   
  },
  
  methods: {
    generar_imprimir(){
          alert('En desarrollo')
    },
    close(){ 
            this.dialogCategoria=false
            this.dialog=false
    },   
    nuevoItem(item){
              this.editItem={}
              this.dialog=true
              this.editItem.idcategoria=item.id
              this.editItem.estado=1
              this.editItem.idcomplejo=this.idcomplejo      
    },
    nuevoItemCategoria(item){
       // alert(JSON.stringify(item))
              this.editItemCategoria={}
              this.dialogCategoria=true
              this.editItemCategoria.idpadre=item.idpadre
              this.editItemCategoria.estado=1
              this.editItemCategoria.idcomplejo=this.idcomplejo      
    },
    editar(item) {   
                  this.dialog=true
                  this.valorOriginal=Object.assign({},item)
                  this.editItem=this.valorOriginal
    },
    editarCategoria(item) {     
              this.dialogCategoria=true
              this.valorOriginal=Object.assign({},item)
              this.editItemCategoria=this.valorOriginal
    },  
    grabarItem(item){
              let resultado=''
              if (!item.id){
                        axios.post(process.env.VUE_APP_API +'/complejo/itemcarta',  {
                                        params: {                                                 
                                                IdCategoria: item.idcategoria,
                                                Item: item.item,
                                                IdComplejo: item.idcomplejo,
                                                Estado: item.estado,
                                                Precio: item.precio,
                                                PrecioSocio: item.preciosocio,
                                                Descripcion: item.descripcion,
                                                Descripcion2: item.descripcion2,
                                                Descripcion3: item.descripcion3                                                                                  
                                                }
                                        })
                                        .then((response) => {
                                            if (response.status == 200){
                                                item.id=response.data.respuesta
                                                this.cartaObtenidaFiltrada.push(item)
                                                this.dialog=false                                              
                                            }
                                            else {
                                              alert("Error en Agregar item")
                                              console.log( response)
                                            }
                                        })
                                        .catch(function() {
                                                            alert("Error")
                                          }) 
                                          // alert(resultado)
                                          return resultado
              }else{ //SI ES ACTUALIZAR
                      axios.put(process.env.VUE_APP_API +'/complejo/itemcarta/',  {
                                        params: {   
                                                id: item.id,                                              
                                                IdCategoria: item.idcategoria,
                                                Item: item.item,
                                                IdComplejo: item.idcomplejo,
                                                Estado: item.estado,
                                                Precio: item.precio,
                                                PrecioSocio: item.preciosocio,
                                                Descripcion: item.descripcion,
                                                Descripcion2: item.descripcion2,
                                                Descripcion3: item.descripcion3                                                                                 
                                                }
                                        })
                                        .then((response) => {
                                            if (response.status == 200){  
                                                let indice= this.cartaObtenidaFiltrada.findIndex(contenido=> contenido.id == response.data.respuesta) 
                                                Object.assign(this.cartaObtenidaFiltrada[indice], item)                                               
                                                this.dialog=false                                              
                                            }
                                            else {
                                              alert("Error en Actualizar item")
                                              console.log( response)
                                            }
                                        })
                                        .catch(function(err) {
                                                            alert(err)
                                          }) 
                       this.dialog=false                      
              }        
    },
    grabarItemCategoria(item){          
              let resultado=''
              if (!item.id){ //si es nuevo                                   
                        axios.post(process.env.VUE_APP_API +'/complejo/itemcartacategoria',  {
                                        params: {            
                                                Categoria: item.categoria,
                                                IdComplejo: item.idcomplejo,
                                                IdPadre: item.idpadre,
                                                Pie: item.pie,
                                                Estado: item.estado                                                                                 
                                                }
                                        })
                                        .then((response) => {                                          
                                            if (response.status == 200){                                           
                                                item.id=response.data.respuesta
                                                this.categoriasObtenidasFiltrada.push(item)  
                                                this.dialogCategoria=false
                                                this.$vuetify.goTo("#final_pagina")
                                            }
                                            else {
                                              alert("Error en Agregar item categoria")
                                              console.log( response)
                                            }
                                        })
                                        .catch(function(err) {
                                                            alert("Error" + err)
                                          }) 
                                          return resultado
              }else{ // ACTUALIZAR CATEGORIA
                      
                      axios.put(process.env.VUE_APP_API +'/complejo/itemcartacategoria/',  {
                                        params: {   
                                                Id: item.id,
                                                Categoria: item.categoria,
                                                IdComplejo: item.idcomplejo,
                                                IdPadre: item.idpadre,
                                                Pie: item.pie,
                                                Estado: item.estado                                                                              
                                                }
                                        })
                                        .then((response) => {
                                            if (response.status == 200){ 

                                              let indice= this.categoriasObtenidasFiltrada.findIndex(contenido=> contenido.id == response.data.respuesta) 
                                                Object.assign(this.categoriasObtenidasFiltrada[indice], item)
                                              //  resultado=response.data.data
                                               
                                               // alert(JSON.stringify(this.valorOriginal))
                                                this.dialogCategoria=false                                              
                                            }
                                            else {
                                              alert("Error en Actualizar item")
                                              console.log( response)
                                            }
                                        })
                                        .catch(function(err) {
                                                            alert(err)
                                          }) 
                      //si es una modificacion falta escribir en base
                        //alert("modificacion")
                       // this.dialogCategoria=false                      
              }        
    },
    borrarCategoria(item) {
        const index = this.categoriasObtenidasFiltrada.indexOf(item)
      //  alert(JSON.stringify(item))
        //falta escribir en base
       // let resultado= ''
       let respuesta= confirm('Esta seguro de borrar la categoria (' +  item.categoria + ') y todos sus items?')
       if (respuesta) {
         //alert('ok')
          axios.delete(process.env.VUE_APP_API +'/complejo/itemcartacategoria/',  {
                                params: {   
                                        Id: item.id                                                                                  
                                        }
                                })
                                .then((response) => {
                                    if (response.status == 200){  
                                       // resultado=response.data.data
                                        this.dialogCategoria=false                                      
                                    }
                                    else {
                                      alert("Error en borrar item: " + item.id)
                                      console.log( response)
                                    }
                                })
                                .catch(function(err) {
                                                    alert(err)
                                   }) 

         this.categoriasObtenidasFiltrada.splice(index, 1)

         }
         else  {
         //  alert('NO')
           } 
    },
    borrar(item) {
     //  alert(JSON.stringify(item))
        const index = this.cartaObtenidaFiltrada.indexOf(item)
        //falta escribir en base
       // let resultado= ''
       let respuesta= confirm('Esta seguro de borrar: (' +  item.item + ') ?')
       if (respuesta) {
         //alert('ok')
          axios.delete(process.env.VUE_APP_API +'/complejo/itemcarta/',  {
                                params: {   
                                        id: item.id                                                                                  
                                        }
                                })
                                .then((response) => {
                                    if (response.status == 200){  
                                       // resultado=response.data.data
                                        this.dialog=false                                      
                                    }
                                    else {
                                      alert("Error en borrar item: " + item.id)
                                      console.log( response)
                                    }
                                })
                                .catch(function(err) {
                                                    alert(err)
                                   }) 

         this.cartaObtenidaFiltrada.splice(index, 1)

         }
         else  {
         //  alert('NO')
           } 
    },
    goto(id) {
      let destino = "#" + id;
      this.$vuetify.goTo(destino);
    },
    cambio(item) {
      this.filtroPorTipo(this.itemTabs[item].idpadre)
    },
    busca() {
      this.buscar = "";
      this.mostrarTab = !this.mostrarTab;
    },
    filtroPorTab(id) {      
      this.filtroPorTipo(id);
    },
    filtroPorCarta(id) {      
      this.cartaObtenidaFiltrada = this.cartaObtenida.filter(
        categoria => categoria.idcategoria === id
      );
    },
    filtroPorTipo(id) {      
      this.categoriasObtenidasFiltrada = this.categoriasObtenidas.filter(
        idpadre => idpadre.idpadre === id
      );      
    },
    filtroPorCategorias(id) {
            let resultado = [];
      let bus = this.buscar;      
      resultado = this.cartaObtenida.filter(
        categoria => categoria.idcategoria === id
      );     
      if (this.buscar) {
        resultado = resultado.filter(function(str) {   
          return str.item.toUpperCase().includes(bus.toUpperCase());
        });
      }      
      //console.log(JSON.stringify(this.cartaObtenida.filter( categoria => categoria.id==113)))
      return resultado;
    }
  }
};
</script>
<style scoped>
.itempar:nth-child(odd) {
  background-color: #fafafa;
}
.v-tab {
  text-transform: none;
  line-height: 0.8rem;
  font-stretch: condensed;
}

.v-tabs-bar.v-tabs-bar--is-mobile .v-tab {
  margin: 0px !important;
  padding: 0px !important;
  min-width: 0;
}
.itemdos {
  margin: 0px;
  padding: 0px;
  border-color: green;
  border-width: 1px;
  height: 25px;
  color: white !important;
  background-color: green !important;
}
.item {
  margin: 0px;
  padding: 0px;
  border-color: green;
  border-width: 1px;
  height: 25px;
  color: green !important;
}
.chips {
  letter-spacing: 0.04em;
  line-height: 0.5rem;
  height: 10px;
  color: red;
}
</style>